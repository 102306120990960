var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(["setDocumentTitle", window.location.hostname + "/" + document.title]);
  if(window.location.hostname.includes("evondt.com")) {
    _paq.push(["setCookieDomain", "*.evondt.com"]);
  }else if(window.location.hostname.includes("evondt.cloud")) {
    _paq.push(["setCookieDomain", "*.evondt.cloud"]);
  }
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  _paq.push(['HeatmapSessionRecording::disableRecordMovements']);
  (function() {
    try{
      var u="https://analytics.evondt.systems/";
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '5']);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    } catch(err) {}
  })();