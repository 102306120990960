(function() {
const script = document.createElement('script');
script.async = true;
script.src = 'https://www.googletagmanager.com/gtag/js?id=G-QWC24KDZVE';

script.onload = () => {
    // 初始化Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
    window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-QWC24KDZVE');
};

document.head.appendChild(script);
})();