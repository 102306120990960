window.chatwootSettings = {"position":"right","type":"standard","launcherTitle":"Chat with us"};
(function(d,t) {
  try{
    var BASE_URL="https://im.evondt.support";
    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=BASE_URL+"/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
      window.chatwootSDK.run({
        websiteToken: 'n7yaL5aMgW6onSUXfWYzr3BZ',
        baseUrl: BASE_URL
      })
    }
  } catch(err) {}
})(document,"script");